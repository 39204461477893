import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import 'bootstrap'
import './App.scss';
import ListCourses from "./pages/ListCourses";
import CourseDetail from "./pages/CourseDetail";
import CurrentCustomer from "./pages/CurrentCustomer";
const Example = () => <p>Example Komponente</p>;

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<ListCourses/>} />
                <Route path="/detail" element={<CourseDetail/>} />
                <Route path="/current" element={<CurrentCustomer/>} />
            </Routes>
        </Router>
    );
};

export default App;
