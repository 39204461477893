import bgImage from '../assets/images/5e6ab9_34722c788cd44b66a771ff29279ab513_mv2.webp'
import {Link} from "react-router-dom";

const ListCourses = () => {

    return (
        <main>
            <div className="container py-5">
                <header className={"mt-3"}>
                    <h4>Alle Kurse</h4>
                </header>
                <section>
                    <div className="row justify-content-start">

                        {/*<div className={"d-flex mb-4 col-md-8 col-lg-6 col-xl-4 align-items-stretch"}>
                            <div className="card with-hover text-black">
                                <img src={bgImage}
                                     className="card-img-top" alt="Apple Computer"/>
                                <div className="card-body">
                                    <div className="text-center">
                                        <h5 className="card-title">Ausbilderlehrgang Flurförderfahrzeuge</h5>
                                        <p className="text-muted mb-4">Irgendwas beschreibendes</p>
                                    </div>
                                    <div>
                                        <div className="d-flex justify-content-center mb-3">
                                            <span> E-Learning + <br/>1 Tag Präsenz oder<br/> 1 Tag LiveOnline Lehrgang</span>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <span>ab </span><span>549,-€</span>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <span>2. und 3. Modul</span><span>399,-€</span>
                                        </div>
                                    </div>
                                    <div
                                        className="d-flex justify-content-center total font-weight-bold mt-4 small">
                                        <span>zzgl. Gesetzl. MwSt</span>
                                    </div>
                                </div>
                            </div>
                        </div>*/
                        }
                        <div className={"d-flex mb-4 col-md-8 col-lg-6 col-xl-4 align-items-stretch"}>
                            <Link to={"/current?course=uvv"} className={"text-decoration-none"}>
                                <div className="card with-hover text-black">
                                    <img src={bgImage}
                                         className="card-img-top" alt="Apple Computer"/>
                                    <div className="card-body">
                                        <div className="text-center">
                                            <h5 className="card-title">UVV Prüfer</h5>
                                            <p className="text-muted mb-4">Lehrgang zur befähigten Person für die
                                                UVV Prüfung von Arbeitsmitteln</p>
                                        </div>
                                        <div>
                                            <div className="d-flex justify-content-center mb-3">
                                                <span> E-Learning + <br/>1 Tag Präsenz oder<br/> 1 Tag LiveOnline Lehrgang</span>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <span>ab </span><span>549,-€</span>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <span>2. und 3. Modul</span><span>399,-€</span>
                                            </div>
                                        </div>
                                        <div
                                            className="d-flex justify-content-center total font-weight-bold mt-4 small">
                                            <span>zzgl. Gesetzl. MwSt</span>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>


                    </div>
                </section>
            </div>
        </main>
    )
}
export default ListCourses