import bgImage from '../assets/images/5e6ab9_34722c788cd44b66a771ff29279ab513_mv2.webp'
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Moment from 'moment';

import {useNavigate, useSearchParams} from "react-router-dom";
import {createRef, useEffect, useState} from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import listPlugin from '@fullcalendar/list';
import multiMonthPlugin from '@fullcalendar/multimonth';
import deLocale from "@fullcalendar/core/locales/de"

const CourseDetail = () => {

    const preEvents = [
        {
            id: 1,
            title: "Live Online Seminar 22",
            date: "2023-02-27",
            color: "black",
            slots: {
                slot1: "1",
                slot2: "2",
                slot3: "12",
            }
        },
        {
            id: 2,
            title: "Live Online Seminar 3 Module verfügbar",
            date: "2023-03-06",
            color: "black",
            slots: {
                slot1: "free",
                slot2: "8",
                slot3: "free",
            }
        },
        {
            id: 3,
            title: "Live Online Seminar",
            date: "2023-03-13",
            color: "black",
            slots: {
                slot1: "free",
                slot2: "free",
                slot3: "free",
            }
        },
        {
            id: 4,
            title: "Dortmund",
            date: "2023-03-15",
            color: "black",
            slots: {
                slot1: "6",
                slot2: "free",
                slot3: "4",
            }
        },
        {
            id: 5,
            title: "Live Online Seminar",
            date: "2023-03-22",
            color: "black",
            slots: {
                slot1: "7",
                slot2: "free",
                slot3: "free",
            }
        },
        {
            id: 6,
            title: "Live Online Seminar 3 Module verfügbar",
            date: "2023-04-19",
            color: "black",
            slots: {
                slot1: "free",
                slot2: "5",
                slot3: "free",
            }
        },
        {
            id: 7,
            title: "Live Online Seminar",
            date: "2023-04-24",
            color: "black",
            slots: {
                slot1: "free",
                slot2: "15",
                slot3: "9",
            }
        },
        {
            id: 8,
            title: "Live Online Seminar 3 Module verfügbar",
            date: "2023-04-27",
            color: "black",
            slots: {
                slot1: "free",
                slot2: "free",
                slot3: "free",
            }
        },
        {
            id: 9,
            title: "Live Online Seminar",
            date: "2023-05-03",
            color: "black",
            slots: {
                slot1: "free",
                slot2: "free",
                slot3: "10",
            }
        },
        {
            id: 10,
            title: "Dortmund",
            date: "2023-05-11",
            color: "black",
            slots: {
                slot1: "free",
                slot2: "free",
                slot3: "free",
            }
        },
        {
            id: 11,
            title: "Live Online Seminar",
            date: "2023-03-15",
            color: "black",
            slots: {
                slot1: "free",
                slot2: "free",
                slot3: "free",
            }
        },
        {
            id: 12,
            title: "Live Online Seminar",
            date: "2023-03-25",
            color: "black",
            slots: {
                slot1: "8",
                slot2: "free",
                slot3: "2",
            }
        },
        {
            id: 13,
            title: "Live Online Seminar",
            date: "2023-06-05",
            color: "black",
            slots: {
                slot1: "free",
                slot2: "2",
                slot3: "free",
            }
        },
        {
            id: 14,
            title: "Live Online Seminar",
            date: "2023-06-13",
            color: "black",
            slots: {
                slot1: "5",
                slot2: "free",
                slot3: "free",
            }
        },
        {
            id: 15,
            title: "Dortmund 3 Module verfügbar",
            date: "2023-06-15",
            color: "black",
            slots: {
                slot1: "free",
                slot2: "1",
                slot3: "free",
            }
        },
        {
            id: 16,
            title: "Live Online Seminar 3 Module verfügbar",
            date: "2023-06-19",
            color: "black",
            slots: {
                slot1: "11",
                slot2: "free",
                slot3: "free",
            }
        }
    ]
    const modules = [
        {id: 1, name: "Absauganlagen / Lüftungstechnische Anlagen", course: "uvv"},
        {id: 2, name: "Baumaschinen", course: "uvv"},
        {id: 3, name: "Elektrische Betriebsmittel", course: "uvv"},
        {id: 4, name: "Fahrzeuge und Lkw", course: "uvv"},
        {id: 5, name: "Flurförderzeuge", course: "uvv"},
        {id: 6, name: "Flüssigkeitsstrahler", course: "uvv"},
        {id: 7, name: "Gerüste", course: "uvv"},
        {id: 8, name: "Hubarbeitsbühnen & Hebebühnen", course: "uvv"},
        {id: 9, name: "Hydraulische Pressen", course: "uvv"},
        {id: 10, name: "Hydraulik- schlauchleitungen", course: "uvv"},
        {id: 11, name: "Krane", course: "uvv"},
        {id: 12, name: "Lastaufnahme-, Anschlag- & Hebemittel", course: "uvv"},
        {id: 13, name: "Leitern & Tritte", course: "uvv"},
        {id: 14, name: "Anderes 1 ", course: "other"},
        {id: 15, name: "Anderes 2", course: "other"},
    ]

    const notify = () => toast("Maximal 3 Module", {position: "top-center", type: "error"});
    const navigate = useNavigate();
    const [list, setList] = useState(JSON.parse(localStorage.getItem("modules")) || [])
    const [searchParams, setSearchParams] = useSearchParams();
    const [events, setEvents] = useState(preEvents)
    const course = searchParams.get("course");
    const current = searchParams.get("course");
    const number = searchParams.get("number");
    const availableModules = modules.filter((item) => item.course === course);
    const calendarRef = createRef()
    const [selected, setSelected] = useState();
    const [chosen, setChosen] = useState([]);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleChose = ({event}) => {
        setChosen(chosen => [...chosen, event])
        let curEvents = events;


        curEvents.forEach(function (curEvent, index, curEvents) {
            if (curEvent.id === event.id) {
                list.forEach(function (mod) {
                    let sel = false
                    if (curEvent.slots.slot1 === "free") {
                        curEvent.slots.slot1 = mod - 1
                        sel = true;
                    }
                    if (!sel && curEvent.slots.slot2 === "free") {
                        curEvent.slots.slot2 = mod - 1
                        sel = true;
                    }
                    if (!sel && curEvent.slots.slot3 === "free") {
                        curEvent.slots.slot3 = mod - 1
                        sel = true;
                    }
                })
            }
        })
        setEvents(curEvents);
        handleClose()
    }
    const handleShow = () => setShow(true);
    useEffect(() => {
        if (localStorage.getItem("course") === course) {
            localStorage.setItem('modules', JSON.stringify(list))
            getFilteredEvents()
        } else {
            setList([]);
            localStorage.setItem("course", course);
            localStorage.setItem('modules', JSON.stringify([]))
        }
    }, [list])
    useEffect(() => {
        console.log(chosen)
    }, [chosen])
    useEffect(() => {
        let api = calendarRef.current.getApi()
        api.removeAllEvents()
        api.addEventSource(events)
    })

    const handleModulClick = event => {
        const dataId = event.currentTarget.dataset.id;
        const found = list.some((arrVal) => dataId === arrVal);
        if (!found) {
            if (list.length == 3 && !current ) {
                notify();
                return;
            }
            setList(list => [...list, dataId])
        } else {
            setList(list.filter(item => item !== dataId))
        }


    }

    const isSet = dataId => {
        return list.some((arrVal) => dataId == arrVal);
    }

    const Total = () => {
        let total = 0;
        if (list.length === 1) {
            total = 549;
        }
        if (list.length > 1) {
            total = 549 + ((list.length - 1) * 399);
        }
        return (
            <>{total}</>
        )
    }

    function findArrayElementById(array, id) {
        return array.filter((element) => {
            return element.id === id;
        })
    }

    const getFilteredEvents = () => {
        let returnEvents = events
        returnEvents.forEach(function (event, index, returnEvents) {
            let av = 0;
            list.forEach(function (entry) {
                let free = 0;
                if (event.slots.slot1 == "free") {
                    free = free + 1
                }
                if (event.slots.slot2 == "free") {
                    free = free + 1
                }
                if (event.slots.slot3 == "free") {
                    free = free + 1
                }
                if (free >= list.length) {
                    av = 3;
                    return
                } else {

                    if (entry == event.slots.slot1) {
                        free = free + 1
                    }
                    if (entry == event.slots.slot2) {
                        free = free + 1
                    }
                    if (entry == event.slots.slot3) {
                        free = free + 1
                    }
                    av = free
                }
            })
            if (av === 0) {
                event.color = "red"
            }
            if (av === 1) {
                event.color = "orange"
            }
            if (av === 2) {
                event.color = "yellow"
            }
            if (av === 3) {
                event.color = "green"
            }
            returnEvents[index] = event;

        })
        setEvents(returnEvents)
    }

    const CartModuleList = () => {
        const selectedModules = modules.filter((el) => list.includes(el.id.toString()));
        return (
            <div>
                <div className={"fw-bold"}>Gewählte Module</div>
                <div className={"mb-2"}>
                    {selectedModules.map(module = (module, index) => (
                            <div key={module.id} className={" d-flex justify-content-between"}>
                                <span>{module.name}</span><span>{index == 0 ? "549" : "399"},-€</span>
                            </div>
                        )
                    )}
                </div>
                <div className={"d-flex justify-content-between"}>
                    <span>Gesamt: </span><span><Total/>,-€</span>
                </div>
                <span className={"small"}>zzgl. gesetzliche Mwst.</span>
                <br/>
                <br/>
                <div className={"fw-bold"}>Gewählte Termine</div>
                {chosen && chosen.map(module = (chose, index) => (
                        <div key={chose.id} className={" d-flex justify-content-between"}>
                            <span>{Moment().format(chose.date)} {chose.title}</span>
                        </div>
                    )
                )}
            </div>
        )
    }
    return (
        <main>
            <button className="btn btn-primary cart-button" type="button" data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                <i className="fa fa-shopping-cart" aria-hidden="true"></i>
                {list.length > 0 && <span className={"cartNumber"}>{list.length}</span>}
            </button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Event Information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selected != null &&
                        <>
                            <strong>Event: {selected.title}</strong><br/>
                            Aktuelle Belegung: <br/>
                            Slot 1: {selected.slots.slot1 !== "free" ? modules[selected.slots.slot1].name : 'Frei'}<br/>
                            Slot 1: {selected.slots.slot2 !== "free" ? modules[selected.slots.slot2].name : 'Frei'}<br/>
                            Slot 1: {selected.slots.slot3 !== "free" ? modules[selected.slots.slot3].name : 'Frei'}<br/>
                        </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    {selected != null &&
                        <>
                            {selected.color !== "red" &&
                                <Button variant="primary" onClick={() => handleChose({event: selected})}>
                                    Buchung hinzufügen
                                </Button>
                            }
                        </>
                    }
                </Modal.Footer>
            </Modal>
            <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasRight"
                 aria-labelledby="offcanvasRightLabel">
                <div className="offcanvas-header">
                    <h5 id="offcanvasRightLabel">UVV Prüfer</h5>
                    <button type="button" className="btn-close text-reset " data-bs-dismiss="offcanvas"
                            aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <h5 id="offcanvasRightLabel">Ausbildernummer: {number}</h5>
                    {list.length > 0
                        ? <CartModuleList/>
                        : <h5>Keine Module gewählt</h5>}
                </div>
            </div>
            <ToastContainer/>
            <div className="container mt-5 mb-5">
                <div className="row d-flex justify-content-center">
                    <div className="col-md-10">
                        <div className="card">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="images p-3">
                                        <div className="text-center p-4">
                                            <img id="main-image" alt={"preview"}
                                                 src={bgImage}
                                                 width="350"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="product p-4">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <a href={"/"} className={"text-decoration-none text-black"}>
                                                <div className="d-flex align-items-center "><i
                                                    className="fa fa-long-arrow-left"></i> <span
                                                    className="ml-1"> Zurück</span>
                                                </div>
                                            </a>
                                            <i className="fa fa-shopping-cart text-muted"></i>
                                        </div>
                                        <div className="mt-4 mb-3"><span
                                            className="text-uppercase text-muted brand">LTS Akademie</span>
                                            <h5 className="text-uppercase">UVV Prüfer </h5>
                                            <div className="price d-flex flex-row align-items-center"><span
                                                className="act-price">ab 549,-€</span>
                                            </div>
                                        </div>
                                        <p className="about">
                                            3 Prüfereignungen an einem Tag möglich
                                            <br/>
                                            <br/>
                                            E-Learning +<br/>
                                            1 Tag Präsenz oder<br/>
                                            1 Tag LiveOnline Lehrgang
                                        </p>
                                    </div>
                                </div>
                                <div className={"col-md-12"}>
                                    <div className="product p-4">
                                        <h4 className={"text-center"}>Lehrgang zur befähigten Person (UVV Prüfer) für
                                            die
                                            UVV Prüfung von Arbeitsmitteln
                                        </h4>

                                        <p>
                                            Möchten Sie sich selbst oder Mitarbeiter Ihres Unternehmens zur befähigten
                                            Person bzw. UVV Prüfer für die UVV-Prüfung von Arbeitsmitteln ausbilden
                                            lassen? Dann sind Sie bei der LTS-Akademie goldrichtig. In unserem Lehrgang,
                                            welcher seitens der Berufsgenossenschaft als anerkannte Ausbildung gilt,
                                            werden die Teilnehmer dazu qualifiziert, Betriebs- und Arbeitsmittel gemäß
                                            der DGUV zu prüfen und fachgerecht zu dokumentieren. Informieren Sie sich
                                            hier und melden Sie sich bequem online für die nächsten Termine an.
                                        </p>
                                        <h4 className={"text-center"}>Ist eine UVV-Prüfung Pflicht?</h4>
                                        <p>
                                            Der Umgang mit technischen Arbeits- und Betriebsmitteln birgt viele
                                            Gefahrenpunkte für die Mitarbeiter. Damit Sicherheit und Gesundheitsschutz
                                            am Arbeitsplatz dennoch gewährleistet werden können, ist der richtige und
                                            sichere Umgang mit Flurförderzeugen, kraftbetriebenen Kleingeräten und Co.
                                            von großer Bedeutung. Dieser ist in den Unfallverhütungsvorschriften (UVV)
                                            definiert:<br/>

                                            Überall dort, wo mit besonderen Betriebsmitteln hantiert und gearbeitet
                                            wird, ist eine jährliche UVV Prüfung verpflichtend.
                                            <br/>
                                            Damit Sie als Unternehmen unkompliziert Ihrer Pflicht zur Schadensprävention
                                            nachkommen können, bieten wir Ihnen in der LTS-Akademie die Qualifizierung
                                            zum UVV Prüfer an.
                                        </p>
                                    </div>
                                    <hr/>
                                    <div className={"module"}>
                                        <h2 className={"text-center"}>Wählen Sie ihre Module</h2>
                                        <div className={"row justify-content-start p-4"}>
                                            {availableModules.map(modul => (
                                                    <div
                                                        key={modul.id}
                                                        className={isSet(modul.id) ? "col-md-2 align-items-stretch module-box mb-3 active" : "col-md-2 align-items-stretch module-box mb-3 "}>
                                                        <div data-id={modul.id}
                                                             onClick={handleModulClick}
                                                             className={"card  with-hover d-flex justify-content-center align-items-center text-center p-4 h-100"}>
                                                            <i className="fa fa-laptop module-icon" aria-hidden="true"></i>
                                                            <p>{modul.name}</p>
                                                        </div>
                                                    </div>

                                                )
                                            )}

                                        </div>
                                    </div>
                                    <hr/>
                                    <div className={"p-2"}>
                                        <h2 className={"text-center"}>Termine</h2>
                                        <FullCalendar
                                            ref={calendarRef}
                                            themeSystem={"bootstrap5"}
                                            locale={deLocale}
                                            headerToolbar={{
                                                start: 'title',
                                                center: '',
                                                end: 'listYear listMonth dayGridMonth prev,next'
                                            }}
                                            buttonText={{
                                                listYear: "Jahresliste",
                                                listMonth: "Monatsliste",
                                                dayGridMonth: "Monatskalender",
                                            }}
                                            plugins={[dayGridPlugin, listPlugin, multiMonthPlugin]}
                                            initialView="listYear"
                                            events={events}
                                            eventClick={
                                                function (arg) {
                                                    Promise.resolve()
                                                        .then(() => {
                                                            setSelected(events.find(element => element.id == arg.event.id))
                                                        })
                                                        .then(() => handleShow())

                                                }
                                            }

                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}
export default CourseDetail;
