import {useState} from "react";
import {elementClosest} from "@fullcalendar/core/internal";
import {useNavigate, useSearchParams} from "react-router-dom";

const CurrentCustomer = () => {
    const existingCustomer = [
        '1637'
    ];
    const [searchParams, setSearchParams] = useSearchParams();
    const course = searchParams.get("course");

    const [isCurrent, setIsCurrent] = useState(false)
    const [yes, setYes] = useState(false)
    const [no, setNo] = useState(false)
    const handleYes = () => {
        setNo(false);
        setYes(true);
    }
    const handleNo = () => {
        setNo(true);
        setYes(false);
    }
    const navigate = useNavigate();

    const [disabled, setDisabled] = useState(true)
    const [valid, setValid] = useState(false)
    const [number, setNumber] = useState()
    const [inputValue, setInputValue] = useState()
    const [alert, setAlert] = useState(false)
    const handleChange = (event) => {
        let i = event.target.value;
        if (event.target.value.length === 4) {
            if (existingCustomer.indexOf(event.target.value) > -1) {
                setAlert(false);
                setValid(true)
                setDisabled(false);
                setIsCurrent(true)
                setNumber(event.target.value)
            } else {
                setAlert(true);
                setInputValue(false)
            }
        }

    }
    const RenderAlert = () => {
        if (alert) {
            return (
                <div className={"alert alert-danger "}>Nicht Gültig</div>
            )
        } else {
            return <></>
        }
    }
    const RenderInput = () => {
        if (valid) {
            return (
                <div className={"alert alert-success "}>Nummer Gültig</div>
            )
        } else {
            return (
                <input onChange={handleChange} type={"text"}
                       className={"form-control mb-2"}/>
            )
        }
    }
    const RenderYes = () => {
        if (yes) {
            return (
                <div className={"isCustomer"}>
                    <div className={"text-center mt-4"}>
                        <h4>Wie lautet Ihre Ausbildernummer ? </h4>
                        <RenderInput/>
                        <RenderAlert/>
                        <button onClick={handleNext} disabled={disabled} className={"btn btn-success mt-2"}>Weiter
                        </button>
                    </div>
                </div>
            )
        } else {
            return <></>
        }

    }
    const handleNext = () => {
        navigate('/detail?course=' + course + '&current=true'+'&number='+number)

    }
    const RenderNo = () => {
        if (no) {
            navigate('/detail?course=' + course)
        } else {
            return <></>
        }
    }

    return (
        <main>
            <div className={"container mt-5 mb-5"}>
                <div className={"row d-flex justify-content-center"}>
                    <div className={"col-md-10"}>
                        <div className={"card p-4"}>
                            <h2 className={"text-center p-2"}>Sind Sie bereits Kunde der LTS-Akademie?</h2>
                            <div className={"d-flex justify-content-center"}>
                                <button onClick={handleYes} type={"button"} className={"btn btn-primary m-2 "}>Ja
                                </button>
                                <button onClick={handleNo} type={"button"} className={"btn btn-danger m-2"}>nein
                                </button>
                            </div>
                            <RenderYes/>
                            <RenderNo/>

                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}


export default CurrentCustomer;